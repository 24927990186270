import { ApolloProvider } from '@apollo/client'
import { useApollo } from '../lib/apolloClient'
import Head from 'next/head'
import { appWithTranslation } from 'next-i18next'
import Script from 'next/script'
import { useRouter } from 'next/router'

import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
config.autoAddCss = false

import '../styles/globals.scss'
import { useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { init as initMatomo } from '@socialgouv/matomo-next'
import CookieConsent, { Cookies } from "react-cookie-consent"


function MyApp({ Component, pageProps }) {
  const apolloClient = useApollo(pageProps.initialApolloState)

  const [cookiesEnabled, setCookiesEnabled] = useState(false)
  const router = useRouter()

  useEffect(() => {
    if (Cookies.get('trnc.gdpr') === 'true') {
      setCookiesEnabled(true)
      const handleRouteChange = (url) => {
        if (window && window._paq) {
          _paq.push(['setCustomUrl', url]);
          _paq.push(['setDocumentTitle', document.title]);
          _paq.push(['trackPageView']);
        }
      }

      router.events.on('routeChangeComplete', handleRouteChange)
    }
  }, [])

  if (typeof window !== 'undefined') {
    const deviceSize = () => {
      const doc = document.documentElement
      doc.style.setProperty('--device-height', `${window.innerHeight}px`)
      let vw = document.documentElement.clientWidth / 100
      doc.style.setProperty('--vw', `${vw}px`)
      let vh = window.innerHeight / 100
      doc.style.setProperty('--vh', `${vh}px`)
    }
    deviceSize()
    window.onresize = deviceSize
  }

  return (
    <ApolloProvider client={apolloClient}>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, user-scalable=no" />
      </Head>
      <AnimatePresence>
        <Component {...pageProps} />
        {cookiesEnabled && (
          <Script id="matomo-tag-manager" strategy="afterInteractive">
            {`
          var _mtm = window._mtm = window._mtm || [];
          _mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
          var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
          g.async = true; g.src = 'https://analytics.tronic.digital/js/container_5fjh18WU.js'; s.parentNode.insertBefore(g, s);
        `}
          </Script>
        )}
        <CookieConsent
          enableDeclineButton
          debug={false}
          disableStyles
          cookieName="trnc.gdpr"
          buttonText="Akzeptieren"
          declineButtonText="Ablehnen"
          onAccept={() => setCookiesEnabled(true)}
          containerClasses="cookieConsent"
          location="top"
          buttonClasses="cookieConsent__buttons cookieConsent__buttons__accept"
          declineButtonClasses='cookieConsent__buttons cookieConsent__buttons__decline'
        >
          Wir verwenden Cookies, um Ihnen die bestmögliche Nutzung unserer Webseite zu ermöglichen. Es werden für den Betrieb der Seite technisch notwendige Cookies gesetzt. Darüber hinaus können Sie Cookies für Statistikzwecke zulassen. <a href="/datenschutzerklaerung">Zur Datenschutzerklärung</a>
        </CookieConsent>
      </AnimatePresence>
    </ApolloProvider>
  );
}

export default appWithTranslation(MyApp)
